import React, { useState } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

import navItems from '../data/nav.json';

import Logo from './logo';
import SocialIcons from './social-icons';
import './header.scss';

navItems.sort((a, b) => {
    if (a.order < b.order) {
        return -1;
    }

    return 1;
});

const Header = ({ pageId }) => {
    const data = useStaticQuery(graphql`
        query ContactInfoQuery {
            site {
                siteMetadata {
                    contactInfo {
                        name
                        telNumber
                        email
                    }
                }
            }
        }
    `);

    const [isMenuOpen, toggleMenu] = useState(false);

    return (
        <header className="header">
            <div className="header__logo">
                <Link to="/" aria-label="Home">
                    <Logo />
                </Link>
            </div>
            <div className="header__contact-info">
                <p>{data.site.siteMetadata.contactInfo.telNumber}</p>
                <p>
                    <a
                        href={`mailto:${data.site.siteMetadata.contactInfo.email}`}
                    >
                        {data.site.siteMetadata.contactInfo.email}
                    </a>
                </p>
                <SocialIcons />
            </div>
            <div className="header__navbar">
                <nav>
                    <div className="navbar">
                        <button
                            className="navbar__mobile-menu-button"
                            type="button"
                            onClick={() => toggleMenu(!isMenuOpen)}
                        >
                            Menu{' '}
                            {!isMenuOpen && (
                                <FontAwesomeIcon icon={faChevronDown} />
                            )}
                            {isMenuOpen && (
                                <FontAwesomeIcon icon={faChevronUp} />
                            )}
                        </button>
                        <ul
                            className={`navbar__items navbar__items--${
                                !isMenuOpen ? 'hide-mobile' : 'show-mobile'
                            }`}
                        >
                            {navItems.map(navItem => {
                                const linkComponent = navItem.isExternal ? (
                                    <a href={navItem.link}>{navItem.name}</a>
                                ) : (
                                    <Link to={navItem.link}>
                                        {navItem.name}
                                    </Link>
                                );

                                return (
                                    <li
                                        className="navbar__item-container"
                                        key={`nav-item-${navItem.id}`}
                                    >
                                        <div className="navbar__item">
                                            <div
                                                className={`navbar__link ${
                                                    pageId === navItem.id
                                                        ? 'navbar__link--current'
                                                        : ''
                                                }`}
                                            >
                                                {linkComponent}
                                            </div>
                                            <div
                                                className={`navbar__droplet ${
                                                    pageId === navItem.id
                                                        ? 'navbar__droplet--current'
                                                        : ''
                                                }`}
                                            />
                                        </div>
                                    </li>
                                );
                            })}
                            <li className="navbar__social-icons">
                                <div className="navbar__item">
                                    <SocialIcons />
                                </div>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </header>
    );
};

Header.propTypes = {
    pageId: PropTypes.string.isRequired,
};

export default Header;
