import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const AccreditationIcon = ({ accreditationName, className }) => {
    const data = useStaticQuery(graphql`
        query {
            napit: file(relativePath: { eq: "accreditations/napit.png" }) {
                childImageSharp {
                    fluid(maxWidth: 300, quality: 50) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            trustMark: file(
                relativePath: { eq: "accreditations/trust-mark.png" }
            ) {
                childImageSharp {
                    fluid(maxWidth: 300, quality: 50) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);

    return (
        <Img
            fluid={data[accreditationName].childImageSharp.fluid}
            className={className}
            alt={`${accreditationName} logo`}
        />
    );
};

AccreditationIcon.propTypes = {
    accreditationName: PropTypes.string.isRequired,
    className: PropTypes.string,
};

AccreditationIcon.defaultProps = {
    className: '',
};

export default AccreditationIcon;
