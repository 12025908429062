import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import SocialIcon from './social-icon';
import './social-icons.scss';

const prepareSocialLinks = ({ whatsAppNumber }) => {
    const encodedWhatsAppNumber = encodeURI(whatsAppNumber);
    const encodedWhatsAppText = encodeURI(
        `Hi. I'd like to enquire about your services.`
    );

    return [
        { name: 'facebook', link: 'https://www.facebook.com/JPFSolutions/' },
        {
            name: 'whatsapp',
            link: `https://wa.me/${encodedWhatsAppNumber}?text=${encodedWhatsAppText}`,
        },
        {
            name: 'instagram',
            link: 'https://www.instagram.com/jpfsolutions/',
        },
    ];
};

const Header = ({ className }) => {
    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        contactInfo {
                            whatsAppNumber
                        }
                    }
                }
            }
        `
    );

    const socials = prepareSocialLinks({
        whatsAppNumber: site.siteMetadata.contactInfo.whatsAppNumber,
    });

    return (
        <div className={`contact-social-icons ${className}`}>
            {socials.map(social => (
                <a
                    href={social.link}
                    className="contact-social-icons__icon"
                    key={`social-icon-${social.name}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label={social.name}
                >
                    <SocialIcon
                        socialName={social.name}
                        className="contact-social-icons__icon"
                    />
                </a>
            ))}
        </div>
    );
};

Header.propTypes = {
    className: PropTypes.string,
};

Header.defaultProps = {
    className: '',
};

export default Header;
