import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import SocialIcons from './social-icons';
import AccreditationLogo from './accreditation-logo';
import './footer.scss';

const Footer = () => {
    const data = useStaticQuery(graphql`
        query ContactInfoQueryFooter {
            site {
                siteMetadata {
                    contactInfo {
                        name
                        telNumber
                        email
                    }
                }
            }
        }
    `);

    return (
        <footer className="footer">
            <div className="footer__accreditations">
                <div className="footer__accreditation-container footer__accreditation-container--napit">
                    <AccreditationLogo
                        accreditationName="napit"
                        className="footer__accreditation-logo"
                    />
                </div>
                <div className="footer__accreditation-container footer__accreditation-container--trust-mark">
                    <AccreditationLogo
                        accreditationName="trustMark"
                        className="footer__accreditation-logo"
                    />
                </div>
            </div>
            <div className="footer__legal">
                <p className="footer__copyright">
                    &copy; {new Date().getFullYear()}{' '}
                    {data.site.siteMetadata.contactInfo.name}
                </p>
                <p>
                    Designed and created by{' '}
                    <a href="https://cshelton.co.uk">Chris Shelton</a>
                </p>
            </div>
            <div className="footer__contact-info">
                <p>{data.site.siteMetadata.contactInfo.telNumber}</p>
                <p>
                    <a
                        href={`mailto:${data.site.siteMetadata.contactInfo.email}`}
                    >
                        {data.site.siteMetadata.contactInfo.email}
                    </a>
                </p>
                <SocialIcons />
            </div>
        </footer>
    );
};

export default Footer;
