/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { config } from '@fortawesome/fontawesome-svg-core';

import Header from './header';
import Main from './main';
import Footer from './footer';
import './layout.scss';
import '../styles/global.scss';

// FontAwesome CSS is imported explicitly in `gatsby-browser.js` to prevent the icons from flashing unstyled on page
// load. Prevent the CSS being loaded again.
config.autoAddCss = false;

const Layout = ({
    children,
    pageId,
    title,
    layoutClassName,
    mainClassName,
}) => {
    const data = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `);

    return (
        <div className={`layout ${layoutClassName}`}>
            <div className="layout__header">
                <Header
                    siteTitle={data.site.siteMetadata?.title || `Title`}
                    className="header"
                    pageId={pageId}
                />
            </div>
            <div className="layout__main">
                <Main title={title} className={mainClassName}>
                    {children}
                </Main>
            </div>
            <div className="layout__footer">
                <Footer />
            </div>
        </div>
    );
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    pageId: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    layoutClassName: PropTypes.string,
    mainClassName: PropTypes.string,
};

Layout.defaultProps = {
    layoutClassName: '',
    mainClassName: '',
};

export default Layout;
