import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.com/docs/use-static-query/
 */

const Logo = ({ socialName, className }) => {
    const data = useStaticQuery(graphql`
        query {
            facebook: file(relativePath: { eq: "socials/facebook.png" }) {
                childImageSharp {
                    fixed(width: 30, height: 30) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            instagram: file(relativePath: { eq: "socials/instagram.png" }) {
                childImageSharp {
                    fixed(width: 30, height: 30) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            whatsapp: file(relativePath: { eq: "socials/whatsapp.png" }) {
                childImageSharp {
                    fixed(width: 30, height: 30) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `);

    return (
        <Img
            fixed={data[socialName].childImageSharp.fixed}
            className={className}
            alt={socialName}
        />
    );
};

Logo.propTypes = {
    socialName: PropTypes.string.isRequired,
    className: PropTypes.string,
};

Logo.defaultProps = {
    className: '',
};

export default Logo;
